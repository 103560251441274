<template>
  <div class="about-automation">
    <div class="my-events">
      <div id="Content">
        <div class="shadow"></div>
        <div class="content-inner">
          <h1>What is Automation?</h1>
          <p>
            The dictionary defines automation as “the technique of making an
            apparatus process or a system operate automatically.”
          </p>
          <p>
            We define automation as “the creation and application of technology
            to monitor and control the production and delivery of products and
            services.”
          </p>
          <p>
            Using our definition, the automation profession includes “everyone
            involved in the creation and application of technology to monitor
            and control the production and delivery of products and services“;
            and the automation professional is “only individual involved in the
            creation and application of technology to monitor and control the
            production and delivery of products and services.“
          </p>
          <p>
            Automation encompasses many vital elements, systems, and job
            functions.
            <br />
            Automation provides benefits to virtually all of industry. Here are
            some examples:
          </p>
          <p>
            Manufacturing, including food and pharmaceutical, chemical and
            petroleum, pulp and paper
            <br />
            Transportation including automotive, aerospace, and rail
            <br />
            Utilities including water and wastewater, oil and gas, electric
            power, and telecommunications
            <br />
            Defense
            <br />
            Facility operations, including security, environmental control,
            energy management, safety, and other building automation
            <br />
            And many others
          </p>
          <p>
            Automation crosses all functions within industry from installation,
            integration, and maintenance to design, procurement, and management.
            Automation even reaches into the marketing and sales functions of
            these industries.
          </p>
          <p>
            Automation involves a very broad range of technology is including
            robotics expert systems, telemetry and communications,
            electro-optics, cyber security, process measurements and control,
            sensors, wireless applications, systems integration, test
            measurement, and many, many more.
          </p>
          <div class="links-wrapper">
            <diyobo-button type="primary" txt="Automate Emails" />
            <diyobo-button type="primary" txt="Automate Surveys" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.about-automation {
  .links-wrapper {
    display: flex;
    justify-content: center;
    margin: 2em auto;
  }
}
// end about-automation
</style>

<script>
import DiyoboButton from "@/components/DiyoboButton";

export default {
  name: "about-automation",
  components: {
    DiyoboButton
  },
  head() {
    return {
      title: "About Automation"
    };
  },
  created() {
    this.$store.commit("setTitle", "Automation");
  }
};
</script>
